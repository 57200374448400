// Node Modules
import React from 'react';

// Components
import MeaningfulConnectionsHero from '../../components/content-sections/course/MeaningfulConnectionsHero';
import CourseContent from '../../components/content-sections/course/CourseContent';
import JoinUsFormWrapper from '../../components/form/presentational/JoinUsFormWrapper';
import Layout from '../../components/core/layout';
import SEO from '../../components/core/seo';

const CourseMeaningfulConnections = () => (
  <Layout displayStickyButton={false}>
    <SEO title="Assuming Remote Control Course" />
    <MeaningfulConnectionsHero />
    <CourseContent
      dripColor="#834B00"
      title="Meaningful Connections Module |&nbsp;15&nbsp;Minutes"
      description="Offers a practical guide to forging a positive and productive remote work&nbsp;culture."
      renderTopics={() => (
        <ul className="greater-than-list">
          <li>Remote Work Culture</li>
          <li>Emotional Intelligence (EI)</li>
          <li>Welcoming New People to the Team</li>
          <li>Availability</li>
          <li>Overcoming Individual Isolation</li>
          <li>Giving Feedback</li>
          <li>Building Trust</li>
          <li>Meaningful Connections Resource Guide (PDF)</li>
        </ul>
      )} />
    <JoinUsFormWrapper buttonText="Register" cta="Register for Meaningful Connections" hideCourses hideInterest interest="Register" selectedCourse="meaningfulConnections" />
  </Layout>
);

export default CourseMeaningfulConnections;
